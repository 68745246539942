<template>
    <layout class="wrapper paper-detail">
        <!-- 面包屑 -->
        <div class="container mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/journal-article' }">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/periodical-list' }">期刊杂志</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <a class="color999" @click="$router.back(-1)">杂志详情</a>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '' }">文章详情</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
            <!-- <a-breadcrumb v-if="type == 2">
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/video-list' }">视频回放</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <a class="color999" @click="$router.back(-1)">视频详情</a>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '' }">论文详情</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb> -->
        </div>
        <div class="container flex">
            <div class="guide-list-left mt20">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article mt10 t-l pb5 mb10">
                        <div class="fs22 strong color333 mb10">{{ infomation.title }}</div>
                    </div>
                    <div class="fs14 mb10 mr20 cursor color333 flex x-left" v-if="infomation.author">
                        <span class="strong color999 t-l">作者：</span>
                        <span class="flex x-left flex-wrap" style="width:calc(100% - 45px);">
                            <span v-for="(item, index) in strToArr(infomation.author, ',')" :key="index"
                                class="mr5">{{ item }}</span>
                        </span>
                    </div>
                    <div class="fs14 mb10 mr20 cursor color333 flex x-left" v-if="infomation.org_cnname">
                        <span class="strong color999 t-l" style="width:45px;">单位：</span>
                        <span class="flex x-left flex-wrap" style="width:calc(100% - 45px);">
                            <span v-for="(item, index) in strToArr(infomation.org_cnname, ';')" :key="index"
                                class="mr5 t-l">{{ item }}</span>
                        </span>
                    </div>
                    <div class="t-l mt15 mb20 content-art fs14" v-if="infomation.abstract">
                        <p class="mb5 color999">摘要</p>
                        <div v-html="infomation.abstract"></div>
                    </div>
                    <div class="fs14 t-l" v-if="infomation.keywords">
                        <span class="mr20 cursor color333 flex x-left mb10">
                            <span class="strong color999" style="width:60px;">关键词：</span>
                            <span class="flex x-left flex-wrap" style="width:calc(100% - 60px);">
                                <span style="color:rgb(0, 76, 161);"
                                    v-for="(item, index) in strToArr(infomation.keywords, ',')" :key="index"
                                    class="mr5">{{ item }}</span>
                            </span>
                        </span>
                        <div class="color333 mb10 fs14" v-if="infomation.source"><span
                                class="strong color999">来源：</span>{{ infomation.source }}</div>
                    </div>
                    <div class="check-paper colorfff" v-if="infomation.filePath"
                        @click="handleOpenPdfEvent(infomation.filePath)">点击查看原文</div>
                    <a-empty style="margin-top:100px;" v-if="!infomation.abstract && !loading" />
                </a-spin>
            </div>
            <div class="guide-list-right mt40">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>


    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import commentList from '@/components/comment-list.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import liveItem from '@/components/live-item.vue';
import verticalMediaItem from '@/components/vertical-media-item.vue'
import storage from 'store'
export default {
    name: 'GuideListDetail',
    components: {
        layout, layoutRight, commonTitle, commentList, acrossMediaItem, liveItem, verticalMediaItem
    },
    data() {
        return {
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            paper_id: this.$route.query.id,
            type: this.$route.query.type || 1,
            infomation: {},
            loading: true,
        }
    },
    created() {
        this.getInfoDetail()
    },
    methods: {
        getInfoDetail() {
            this.loading = true
            this.request.post('PeriodicalPaperDetail', { paper_id: this.paper_id, type: this.type }).then(res => {
                this.infomation = res.data
                this.loading = false
            })
        },
        strToArr(str, s, len) {
            var arr = str ? (str.split(s || ',')) : [];
            return len ? arr.slice(0, len) : arr;
        },
        strReplace(str, s, r) {
            var reg = new RegExp(s, 'g');
            return str ? (str.replace(reg, r)) : '';
        },
        handleOpenPdfEvent(filePath) {
            window.open(filePath, "_blank")
        }
    }
}
</script>
<style lang="less" scoped>
.guide-list-left {
    width: 770px;

    .guidelist-breadcrumb {
        width: 300px;
        height: 20px;

        .guide-ant-breadcrumb {
            float: left;
            color: #C1C1C1;
        }
    }

    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #D8D8D8;

        p {
            margin-bottom: 0;
        }
    }

    .content-art {
        width: 100%;

        /deep/ img {
            max-width: 100%;
            height: auto;
        }

    }

    .guidelist-download {
        width: 100%;
        height: 44px;
        cursor: pointer;

        .color-member {
            color: #1E51C9;
        }

        .download-read {
            width: 104px;
            height: 44px;
            background: linear-gradient(to right, #5F96E6, #1E51C9);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
        }

        .download-read-new {
            height: 40px;
            background: linear-gradient(to right, #5F96E6, #1E51C9);
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            padding: 0 10px 0 10px;
        }
    }

    .guidelist-banner {
        width: 100%;
        height: 187px;
    }

    .check-paper {
        width: 110px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        margin: 20px auto 0;
        cursor: pointer;
        background: linear-gradient(to right, #5F96E6, #1E51C9);
    }
}

.guide-list-right {
    width: 398px;
}
@media screen and (max-width:768px){
    .paper-detail{
        .container{
            display: block;
            padding:0 10px;
        }
        .mobile-bread{
            display: flex;
        }
        .guide-list-left,.guide-list-right,.meeting-right{
            width: 100%;
            padding-top:20px;
        }
        .meeting-report{
            width: 100%;
        }
    }
}
</style>
